import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { ButtonContent } from '../boton/ButtonContent'
import Navmenu from './NavMenu'



const HeaderTwo = () => {

    const { rpdata } = useContext(GlobalDataContext)

    return (
        <header className='bg2 w-full top-0 z-50'>
            <div className="bg-2 p-2">
                <div className="md:w-4/5 mx-auto md:flex justify-center space-x-10 items-center">
                    <ul className="flex justify-center space-x-4 pb-2 md:pb-0">
                        <li className="text-white md:text-[17px] text-[13px] hidden md:block ">
                            <span className="border-r-[1px] border-white pr-3">

                                {rpdata?.dbPrincipal?.location[0]?.address}
                            </span>
                        </li>
                        <li className="text-white md:text-[17px] text-[13px] ">
                            <span className="border-r-[1px] border-white pr-3">

                                {rpdata?.dbPrincipal?.workdays[0]?.day}
                            </span>
                        </li>
                        <li className="text-white md:text-[17px] text-[13px] ">
                            <span className="pr-3">

                                {rpdata?.dbPrincipal?.workHours[0]?.hour}
                            </span>
                        </li>
                    </ul>
                    <ul className="flex justify-center space-x-7">
                        {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                            return (
                                <li key={index} className="text-white">
                                    <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                        <i
                                            className={`fab fa-${item.icon}`}
                                            aria-hidden="true"
                                        />
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            <div className='md:pl-20 md:py-0 py-5 w-[100%] mr-0 md:w-[35%] lg:w-[25%] lg:mr-10'>
                <img
                    src={rpdata?.dbPrincipal?.logo}
                    alt='logo'
                    loading='lazy'
                    className='w-[280px] md:absolute ml-12 relative bg-white rounded-lg md:-mt-[45px] mt-0 z-50'
                />
            </div>


            <div className='bg-white py-4 mx-auto justify-center flex'>
                <div className='flex justify-between items-center md:-mr-32 mr-0'>
                    <nav className='flex items-center justify-around'>
                        <Navmenu />
                        <div className='w-[85%] lg:w-[25%] text-end ml-0 lg:ml-7 pr-5 items-center'>
                            <ButtonContent />
                        </div>
                    </nav>
                </div>
            </div>

        </header>
    )
}

export default HeaderTwo